import React from "react";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import fontDev from '../admin/THSarabun.ttf';
import base64Logo from '../admin/logoBase64';
import LogoPD from "./LogoPD";

Font.register({ family: "THSarabun", src: fontDev });

const styles = StyleSheet.create({
  page: { padding: 20, fontFamily: "THSarabun" },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: 'center', // Align title to the center
  },
  titleHead: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  textRight: {
    fontSize: 12,
    textAlign: 'right', // Align text to the right
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  titleContainer: {
    flex: 1,
    marginLeft: 20,
  },
  addressSubTitle: {
    fontSize: 10,
    marginBottom: 5,
    lineHeight: 1.2,
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",

  },
  tableCol: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    textAlign: "center",
    fontSize: 12
  },
  summary: {
    textAlign: 'right',
    fontSize: 12,
    marginTop: 10,
  },

  signatureContainer: {
    marginTop: 80,
    flexDirection: 'row',
    justifyContent: 'space-between', // Change this to flex-end to align items to the right
  },
  signatureBox: {
    width: '30%',
    borderWidth: 1,
    borderColor: "#FFFFFF",
    padding: 10,
    minHeight: 100,
    textAlign: 'center',
    alignSelf: 'flex-end', // Add this line to align the box to the right
  },
  signatureText: {
    fontSize: 12,
    marginBottom: 20,
  },
  signatureLine: {
    marginTop: 20,
    borderTopWidth: 1,
    borderColor: "#000",
    width: '60%',
    alignSelf: 'center',
  },
  receiverText: {
    fontSize: 12,
    marginTop: 15,
  },
  bottomRightContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    alignItems: 'flex-end',
  },
  bottomRightLogo: {
    width: 60,
    height: 60,
    marginBottom: 5,
  },
  bottomRightText: {
    fontSize: 10,
    textAlign: 'right',
  },
});

const sanitizedText = (text) => {
  return typeof text === 'string' ? text.replace(/<[^>]+>/g, '') : '';
};

const OrderPDF = ({ order }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={LogoPD} />
        <View style={styles.titleContainer}>
          <Text style={styles.titleHead}>บริษัท พีดี มาร์เก็ตติ้ง อินโนเวท จำกัด</Text>
          <Text style={styles.titleHead}>PD MARKETING INNOVATE CO.,LTD</Text>
          <Text style={styles.addressSubTitle}>
            50/228 หมู่บ้านมัณฑนา พระรามเก้าศรีนครินทร์{"\n"}
            ซอยกรุงเทพกรีฑา7 แขวงหัวหมาก เขตบางกะปิ กรุงเทพฯ 10240{"\n"}
            เลขที่ 0105563047244 Tel:020964499{"\n"}
            Email: pd.marketinginnovate@gmail.com
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>ใบเสร็จ/ ใบกำกับภาษี</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.textRight}>เลขที่ใบกำกับ: {sanitizedText(order.orderNumber)}</Text>
        <Text style={styles.textRight}>สาขา: {sanitizedText(order.branch?.name) || "-"}</Text>
        <Text style={styles.textRight}>วันที่ออกใบเสร็จ: {new Date(order.createdAt).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}</Text> {/* เพิ่มส่วนนี้เพื่อแสดงวันที่ */}
        <Text style={styles.textRight}>ผู้ทำรายการ: {order.createdBy?.name || "-"}</Text>
      </View>

      {/* Table for Order Items */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>สินค้า</Text>
          <Text style={styles.tableCol}>จำนวน</Text>
          <Text style={styles.tableCol}>ราคา</Text>
          <Text style={styles.tableCol}>ช่องทางการชำระเงิน</Text> {/* เพิ่มคอลัมน์ใหม่ */}
        </View>
        {order.items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCol}>
              {item.product?.title || item.customProduct || "-"} {/* แสดงชื่อสินค้า หรือ customProduct */}
            </Text>
            <Text style={styles.tableCol}>{item.quantity}</Text>
            <Text style={styles.tableCol}>{item.price}</Text>
            <Text style={styles.tableCol}>
              {order.paymentMethod === "cash"
                ? "เงินสด"
                : order.paymentMethod === "card"
                  ? "บัตรเครดิต/เดบิต"
                  : order.paymentMethod === "bankTransfer"
                    ? "โอนเงินผ่านธนาคาร"
                    : "-"}
            </Text> {/* แสดงช่องทางการชำระเงิน */}
          </View>
        ))}
      </View>

      <View style={styles.summary}>
        <Text>รวมราคาสินค้า (ไม่รวม VAT): {order.totalWithoutVat.toFixed(2)} บาท</Text>
        <Text>VAT (7%): {order.vat.toFixed(2)} บาท</Text>
        <Text>รวมทั้งหมด: {order.cartTotal.toFixed(2)} บาท</Text>
      </View>

      <View style={styles.signatureContainer}>
        <View style={styles.signatureBox}>
          <Text style={styles.signatureText}></Text>
          <Text style={styles.receiverText}>ผู้รับสินค้า ......................................................</Text>
          <Text style={styles.receiverText}>
            วันที่ {new Date(order.createdAt).toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Text>
        </View>
        <View style={styles.signatureBox}>
          <Text style={styles.signatureText}>
            ในนามบริษัท บริษัท พีดี มาร์เก็ตติ้ง อินโนเวท จำกัด
          </Text>
          <Text style={styles.receiverText}>ผู้ส่งสินค้า ......................................................</Text>
          <Text style={styles.receiverText}>
            วันที่ {new Date(order.createdAt).toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Text>
        </View>
      </View>
      <View style={styles.bottomRightContainer}>
        <Image style={styles.bottomRightLogo} src={base64Logo} />
        <Text style={styles.bottomRightText}>360° HEALTHY SHOP</Text>
      </View>
    </Page>
  </Document>
);

export default OrderPDF;


