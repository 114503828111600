import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserCart, saveAddress, saveOrder, emptyCart, getUserProfile } from "../function/users";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spin, Modal } from "antd";
import FileUpload from "./FileUpload";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import Swal from 'sweetalert2';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckOut = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [total, setTotal] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [addressSaved, setAddressSaved] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const [provinces, setProvinces] = useState([]);
  const [amphures, setAmphures] = useState([]);
  const [tambons, setTambons] = useState([]);
  const [addresshome, setAddresshome] = useState([false]);

  const [paymentMethod, setPaymentMethod] = useState("bankTransfer");

  
  const [selected, setSelected] = useState({
    province: '',
    amphure: '',
    tambon: '',
    zip_code: ''
  });

  const [extractedText, setExtractedText] = useState("");

  
  useEffect(() => {
    if (user && user.token) {
      getUserProfile(user.token)
        .then((res) => {
          console.log("User Data:", res.data);
          if (res.data.address) {
            setAddresshome(res.data.address.address || "");
            setPhone(res.data.address.phone || "");
            setName(res.data.address.name || "");
            setEmail(res.data.address.email || "");
          }
        })
        .catch((err) => {
          console.error("Error fetching user profile:", err);
        });
    }
  }, [user]);

  useEffect(() => {
    if (user && user.token) {
      getUserCart(user.token)
        .then((res) => {
          setProducts(
            res.data.products.map((item) => {
              let productData = item.product || item.productservice || item.productmenu;

              // หากเป็น ProductMenu จะต้องดึงข้อมูลจาก productmenu
              if (item.productmenu) {
                // productmenu ที่ดึงมาจาก categoryMenu
                productData = item.productmenu.categoryMenu.subCategories.products.find(
                  (product) => product._id.toString() === item.productmenu.toString()
                );
              }

              return {
                ...item,
                title: productData ? productData.title : 'Unknown',  // ตรวจสอบการแสดงชื่อสินค้า
                price: item.discountedPrice || (productData ? productData.price : 0),  // ใช้ราคาที่เหมาะสม
              };
            })
          );
          setTotal(res.data.cartTotal); // คำนวณยอดรวม
        })
        .catch((err) => {
          console.error('Error fetching cart:', err);
        });
    }

    console.log("sdsd" + getUserCart);
    fetch(
      "https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tambon.json"
    )
      .then((response) => response.json())
      .then((result) => {
        setProvinces(result);
      });
  }, [user.token]);

  const DropdownList = ({
    label,
    id,
    list,
    child,
    childsId = [],
    setChilds = [],
  }) => {
    const onChangeHandle = (event) => {
      setChilds.forEach((setChild) => setChild([]));
      const entries = childsId.map((child) => [child, '']);
      const unSelectChilds = Object.fromEntries(entries);

      const input = event.target.value;
      const selectedItem = list.find((item) => item.name_th === input);
      setSelected((prev) => ({
        ...prev,
        ...unSelectChilds,
        [id]: selectedItem ? selectedItem.name_th : '',
        zip_code: selectedItem && selectedItem.zip_code ? selectedItem.zip_code : ''
      }));

      if (!selectedItem) return;

      if (child) {
        const { [child]: childs } = selectedItem;
        const [setChild] = setChilds;
        setChild(childs);
      }
    };

    return (
      <>
        <label htmlFor={label}>{label}</label>
        <select value={selected[id]} onChange={onChangeHandle} className="form-control">
          <option value="">Select ...</option>
          {list.map((item) => (
            <option key={item.id} value={item.name_th}>
              {item.name_th} - {item.name_en}
            </option>
          ))}
        </select>
      </>
    );
  };

  const handleSaveAddress = async () => {
    if (!name || !phone || !address || !email || !selected.province || !selected.amphure || !selected.tambon) {
      // await Swal.fire({
      //   title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      //   text: 'กรุณากรอกข้อมูลทั้งหมดก่อนทำการบันทึกที่อยู่',
      //   icon: 'error',
      //   confirmButtonText: 'ตกลง',
      // });
      return false;
    }

    const fullAddress = `${address} ${selected.province}, ${selected.amphure}, ${selected.tambon}, ${selected.zip_code}`;
    const addressData = { name, phone, address: fullAddress, email };

    try {
      const res = await saveAddress(user.token, addressData);
      if (res.data?.ok) {
        await Swal.fire({
          title: 'บันทึกข้อมูลที่อยู่สำเร็จ',
          text: 'ที่อยู่ของคุณถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง',
        });
        setAddressSaved(true);
        return true;
      }
    } catch (err) {
      console.error("Error saving address:", err);
      await Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลที่อยู่ได้',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      });
      return false;
    }
  };

  const handleCreateOrder = async () => {
    setLoading(true);

    // const addressSaved = await handleSaveAddress();
    // if (!addressSaved) {
    //   setLoading(false);
    //   return;
    // }
    await handleSaveAddress();

    if (paymentMethod === "cod") {
      saveOrder(user.token, images, paymentMethod)
        .then((res) => {
          if (res?.data) {
            console.log(res.data);
            emptyCart(user.token);
            dispatch({ type: "ADD_TO_CART", payload: [] });
            if (typeof window !== "undefined") localStorage.removeItem("cart");
            toast.success("Save Order Success");
            navigate("/user/order-tracking");
          } else {
            toast.error("Save Order Failed");
          }
        })
        .catch((error) => {
          console.error("Error creating order:", error);
          toast.error("Save Order Failed");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (paymentMethod === "stripe") {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API}/create-checkout-session`, {
          cart: products,
          userId: user._id,
        });

        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });

        if (result.error) {
          toast.error(result.error.message);
        }
      } catch (error) {
        console.error("Error creating Stripe checkout session", error);
        toast.error("ไม่สามารถเข้าสู่หน้าชำระเงิน Stripe ได้");
      }
    } else {
      saveOrder(user.token, images, paymentMethod)
        .then((res) => {
          if (res?.data) {
            console.log(res.data);
            emptyCart(user.token);
            dispatch({ type: "ADD_TO_CART", payload: [] });
            if (typeof window !== "undefined") localStorage.removeItem("cart");
            toast.success("Save Order Success");
            navigate("/user/order-tracking");
          } else {
            toast.error("Save Order Failed");
          }
        })
        .catch((error) => {
          console.error("Error creating order:", error);
          toast.error("Save Order Failed");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };





  return (
    <>
      <div className="container">
        <div
          className="row mt-5 mb-5"
          style={{
            boxShadow: "0 3px 5px rgba(150, 27, 78, 0.7)",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <div className="col-md-6">
            <h5>Address</h5>

            {!isEditing ? (
              // 🧐 โหมดดูข้อมูล
              <div>
                <p><strong>Name:</strong> {name}</p>
                <p><strong>Phone:</strong> {phone}</p>
                <p><strong>Address:</strong> {addresshome}</p>
                {/* <p><strong>Zip Code:</strong> {selected.zip_code}</p> */}
                <p><strong>Email:</strong> {email}</p>

                <button className="btn btn-warning" onClick={() => setIsEditing(true)}>
                  Edit
                </button>
              </div>
            ) : (
              // ✏️ โหมดแก้ไข
              <div>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your full name"
                  />
                </div>

                <div className="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter your phone number"
                  />
                </div>

                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter your address"
                  />
                </div>

                {/* Dropdowns สำหรับเลือกจังหวัด/เขต */}
                <DropdownList label="Province: " id="province" list={provinces} child="amphure" childsId={["amphure", "tambon"]} setChilds={[setAmphures, setTambons]} />
                <DropdownList label="District: " id="amphure" list={amphures} child="tambon" childsId={["tambon"]} setChilds={[setTambons]} />
                <DropdownList label="Sub-district: " id="tambon" list={tambons} />

                <div className="form-group">
                  <label>Zip Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selected.zip_code}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </div>

                <button
                  className="btn btn-primary m-2"
                  onClick={() => {
                    handleSaveAddress({ name, phone, address, email, zip_code: selected.zip_code });
                    setIsEditing(false); // ปิดโหมดแก้ไข
                  }}
                  style={{
                    backgroundColor: "rgb(233, 57, 139)",
                    borderColor: "rgb(233, 57, 139)",
                  }}
                >
                  Save Address
                </button>

                <button className="btn btn-secondary m-2" onClick={() => setIsEditing(false)}>
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div
            className="col-md-6"
            style={{
              boxShadow: "0 3px 5px rgba(150, 27, 78, 0.7)",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <h5>Order Summary</h5>
            <hr />
            <p>Product {products.length}</p>
            <hr />
            <ul>
              {products.map((item, i) => (
                <li key={i}>
                  {/* ตรวจสอบว่าเป็นประเภทใดแล้วแสดงข้อมูลที่เหมาะสม */}
                  {item.product ? (
                    item.product.title
                  ) : item.productmenu ? (
                    item.productmenu.subCategories.map((subCategory) =>
                      subCategory.products.map((product) =>
                        product._id.toString() === item.productmenu.toString()
                          ? product.title
                          : ''
                      )
                    )
                  ) : (
                    'Unknown'
                  )}
                  x {item.count} = {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(item.price * item.count)}
                </li>
              ))}
            </ul>

            <hr />
            Total: <b>${total}</b>
            <hr />
            <div>
              <label>เลือกวิธีการชำระเงิน:</label>
              <div>
                <input
                  type="radio"
                  value="bankTransfer"
                  checked={paymentMethod === "bankTransfer"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                โอนผ่านบัญชีธนาคาร
              </div>
              {/* <div>
                <input
                  type="radio"
                  value="stripe"
                  checked={paymentMethod === "stripe"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                ชำระผ่านบัตรเครดิต
              </div> */}
              <div>
                <input
                  type="radio"
                  value="cod"  // เพิ่มช่องทางการชำระเงินปลายทาง
                  checked={paymentMethod === "cod"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                ชำระเงินปลายทาง
              </div>
            </div>
            {paymentMethod === "bankTransfer" && (
              <>
                <hr />
                <p>ช่องทางการชำระเงิน: โอนผ่านบัญชีธนาคาร</p>
                <p>ธนาคารกสิกรไทย : 067-3-91773-9</p>
                <p>บริษัท พีดี มาร์เก็ตติ้ง อินโนเวท จำกัด</p>
                <hr />
                <p>
                  อัพโหลดสลิป | หลักฐานการชำระเงิน{" "}
                  <FileUpload images={images} setImages={setImages} setExtractedText={setExtractedText} />
                </p>
              </>
            )}
            <button
              className="btn btn-primary m-2"
              onClick={handleCreateOrder}
              disabled={loading}
              style={{
                backgroundColor: "rgb(233, 57, 139)",
                borderColor: "rgb(233, 57, 139)",
            }}
            >
              {loading ? <Spin /> : "Place Order"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;