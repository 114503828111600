import React, { useEffect, useState } from "react";
import { getBranchOrders, getBranchOrdersByBranch, getBranches } from "../../function/branch";
import OrderPopup from "./OrderPopup";
import { Pagination } from "antd"; // ✅ ใช้ Pagination ของ antd

const BranchOrderList = () => {
  const [orders, setOrders] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [userRole, setUserRole] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);

  useEffect(() => {
    loadUserRole();
    loadBranches();
    loadOrders();
  }, []);

  const loadUserRole = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.role) {
        setUserRole(user.role);
        if (user.role === "staff") {
          setSelectedBranch(user.branch?._id || "");
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const loadOrders = async (branchId = "") => {
    try {
      setLoading(true);
      const authtoken = localStorage.getItem("token");
      let res;

      if (branchId) {
        res = await getBranchOrdersByBranch(authtoken, branchId);
      } else {
        res = await getBranchOrders(authtoken);
      }

      setOrders(res.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (error.response && error.response.status === 401) {
        alert("คุณไม่มีสิทธิ์ดูออเดอร์ของสาขานี้");
      }
    } finally {
      setLoading(false);
    }
  };

  const loadBranches = async () => {
    try {
      const authtoken = localStorage.getItem("token");
      const { data } = await getBranches(authtoken);
      setBranches(data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const handleBranchChange = (e) => {
    const branchId = e.target.value;

    if (userRole === "staff") {
      alert("คุณไม่มีสิทธิ์เลือกสาขา");
      return;
    }
    console.log("Selected Branch ID:", branchId);
    setSelectedBranch(branchId);
    loadOrders(branchId);
  };

  // ตัดออเดอร์เฉพาะหน้าปัจจุบัน
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  return (
    <div className="container mx-auto p-4 mt-5">
      <h2
        className="font-bold mb-4" // ยังคงใช้ className สำหรับการจัดการอื่น ๆ
        style={{ fontSize: "22px",  }} // ปรับขนาดตัวอักษรและสี
      >
        รายการคำสั่งซื้อสาขา
      </h2>

      {/* Dropdown เลือกสาขา */}
      <div className="mb-4">
        <label className="mr-2 font-semibold">เลือกสาขา:</label>
        <select
          value={selectedBranch}
          onChange={handleBranchChange}
          className="border px-4 py-2 rounded"
          disabled={userRole === "staff"}
        >
          <option value="">ทั้งหมด</option>
          {branches.map((branch) => (
            <option key={branch._id} value={branch._id}>
              {branch.name}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p>กำลังโหลดข้อมูล...</p>
      ) : (
        <>
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2">หมายเลขออเดอร์</th>
                <th className="border px-4 py-2">สาขา</th>
                {/* <th className="border px-4 py-2">สถานะ</th> */}
                <th className="border px-4 py-2">ผู้ทำรายการ</th>
                <th className="border px-4 py-2">วันที่</th>
                <th className="border px-4 py-2">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.length > 0 ? (
                currentOrders.map((order) => (
                  <tr key={order._id} className="text-center">
                    <td className="border px-4 py-2">{order.orderNumber}</td>
                    <td className="border px-4 py-2">{order.branch?.name || "-"}</td>
                    {/* <td className="border px-4 py-2">{order.orderStatus}</td> */}
                    <td className="border px-4 py-2">{order.createdBy?.name || "-"}</td>
                    <td className="border px-4 py-2">
                      {/* แปลงวันที่สร้างให้อยู่ในรูปแบบที่อ่านง่าย */}
                      {new Date(order.createdAt).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => setSelectedOrder(order)}
                        style={{ backgroundColor: "#6c757d", color: "white", border: "1px solid ", }} // สีเขียว
                        className="px-4 py-2 rounded"
                      >
                        ดูรายละเอียด
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">ไม่มีคำสั่งซื้อ</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* ✅ ใช้ Pagination ของ Ant Design */}
          <div className="flex justify-center mt-4">
            <Pagination
              current={currentPage}
              pageSize={ordersPerPage}
              total={orders.length}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      )}

      {/* แสดง Popup เมื่อเลือกออเดอร์ */}
      {selectedOrder && <OrderPopup order={selectedOrder} onClose={() => setSelectedOrder(null)} />}
    </div>
  );
};

export default BranchOrderList;
