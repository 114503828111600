import React, { useState, useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux"; // ใช้สำหรับดึงข้อมูล user จาก Redux
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminPanel = () => {
    const [image, setImage] = useState(null);
    const [altText, setAltText] = useState("");
    const [previewImages, setPreviewImages] = useState([]); // สำหรับแสดงตัวอย่างรูปภาพ
    const { user } = useSelector((state) => ({ ...state })); // ดึงข้อมูล user จาก Redux
    const [uploadedImages, setUploadedImages] = useState([]); // รูปภาพทั้งหมด
    const [selectedImages, setSelectedImages] = useState([]); // รูปภาพที่เลือกสำหรับ Demo
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUploadedImages = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API + "/admin/getimages-carousel", {
                    headers: {
                        authtoken: user.token,
                    },
                });

                const allImages = response.data;
                setUploadedImages(allImages);

                // กรองเฉพาะรูปภาพที่มี isSelected: true และตั้งค่าใน selectedImages
                const selected = allImages.filter((image) => image.isSelected);
                setSelectedImages(selected);
            } catch (error) {
                console.error("Error fetching uploaded images:", error);
            }
        };

        fetchUploadedImages();
    }, [user.token]);
    useEffect(() => {
        // ดึงข้อมูลรูปภาพจากฐานข้อมูล
        const fetchUploadedImages = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API + "/admin/getimages-carousel", {
                    headers: {
                        authtoken: user.token,
                    },
                });
                setUploadedImages(response.data);
            } catch (error) {
                console.error("Error fetching uploaded images:", error);
            }
        };

        fetchUploadedImages();
    }, [user.token]);

    const handleSelectImage = (image) => {
        const updatedImages = uploadedImages.map((img) =>
            img._id === image._id ? { ...img, isSelected: !img.isSelected } : img
        );

        setUploadedImages(updatedImages);

        // อัปเดต selectedImages
        const selected = updatedImages.filter((img) => img.isSelected);
        setSelectedImages(selected);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewImages([...previewImages, URL.createObjectURL(file)]); // เพิ่ม URL ชั่วคราวสำหรับแสดงตัวอย่าง
        }
    };

    const handleImageUpload = async (e) => {
        e.preventDefault();
      
        if (!image) {
          toast.error("กรุณาเลือกรูปภาพก่อนอัปโหลด");
          return;
        }
      
        try {
          setLoading(true); // เริ่มแสดง Loading
          console.log("เริ่มการอัปโหลดรูปภาพ...");
          const reader = new FileReader();
          reader.readAsDataURL(image);
      
          reader.onloadend = async () => {
            try {
              const base64Image = reader.result;
              console.log("Base64 Image:", base64Image);
      
              // อัปโหลดรูปภาพไปยัง API
              const response = await axios.post(
                process.env.REACT_APP_API + "/images",
                { image: base64Image },
                {
                  headers: {
                    authtoken: user.token, // ส่ง token สำหรับการตรวจสอบสิทธิ์
                  },
                }
              );
      
              console.log("Response จาก /images:", response.data);
      
              const imageUrl = response.data.url; // URL ของรูปภาพที่อัปโหลดสำเร็จ
      
              // บันทึกข้อมูลรูปภาพลง MongoDB
              const saveResponse = await axios.post(
                process.env.REACT_APP_API + "/admin/saveimage-carousel",
                {
                  url: imageUrl,
                  alt: altText,
                },
                {
                  headers: {
                    authtoken: user.token, // ส่ง token สำหรับการตรวจสอบสิทธิ์
                  },
                }
              );
      
              console.log("Response จาก /admin/saveimage-carousel:", saveResponse.data);
      
              // เพิ่มรูปภาพใหม่ในรายการ Uploaded Images
              setUploadedImages((prev) => [...prev, saveResponse.data]);
      
              toast.success("อัปโหลดรูปภาพสำเร็จ!");
              setImage(null);
              setAltText("");
              setPreviewImages([]); // ล้างตัวอย่างรูปภาพหลังอัปโหลดสำเร็จ
            } catch (error) {
              console.error("Error in onloadend:", error);
              toast.error("การอัปโหลดล้มเหลว");
            } finally {
              setLoading(false); // ซ่อน Loading เมื่อเสร็จสิ้น
            }
          };
      
          reader.onerror = () => {
            console.error("Error reading file");
            toast.error("เกิดข้อผิดพลาดในการอ่านไฟล์");
            setLoading(false); // ซ่อน Loading เมื่อเกิดข้อผิดพลาด
          };
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error("การอัปโหลดล้มเหลว");
        }
      };
    const handleSaveSelectedImages = async () => {
        try {
            const selectedImageIds = uploadedImages.map((image) => ({
                _id: image._id,
                isSelected: image.isSelected,
            })); // ดึงเฉพาะ ID ของรูปภาพที่เลือก

            await axios.post(
                process.env.REACT_APP_API + "/admin/save-selected-images",
                { selectedImages: selectedImageIds },
                {
                    headers: {
                        authtoken: user.token, // ส่ง token สำหรับการตรวจสอบสิทธิ์
                    },
                }
            );

            alert("บันทึกรายการรูปภาพที่เลือกสำเร็จ!");
        } catch (error) {
            console.error("Error saving selected images:", error);
            alert("การบันทึกรายการรูปภาพที่เลือกล้มเหลว");
        }
    };

    const handleDeleteImage = async (id) => {
        try {
            // เรียก API เพื่อลบรูปภาพ
            await axios.delete(process.env.REACT_APP_API + `/admin/deleteimage-carousel/${id}`, {
                headers: {
                    authtoken: user.token, // ส่ง token สำหรับการตรวจสอบสิทธิ์
                },
            });

            // อัปเดตรายการรูปภาพใน state หลังจากลบสำเร็จ
            setUploadedImages(uploadedImages.filter((image) => image._id !== id));
            setSelectedImages(selectedImages.filter((image) => image._id !== id));

            alert("ลบรูปภาพสำเร็จ!");
        } catch (error) {
            console.error("Error deleting image:", error);
            alert("การลบรูปภาพล้มเหลว");
        }
    };

    const sortedImages = [...uploadedImages].sort((a, b) => {
        if (a.isSelected === b.isSelected) return 0; // ถ้า isSelected เท่ากัน ไม่ต้องเปลี่ยนลำดับ
        return a.isSelected ? -1 : 1; // ถ้า a.isSelected เป็น true ให้ a อยู่ก่อน
      });

    return (
        <div className="container mt-5">
            
            <h2>Admin Panel - อัปโหลดรูปภาพ Carousel</h2>
            <form onSubmit={handleImageUpload}>
                <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                        เลือกรูปภาพ:
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        id="image"
                        onChange={handleImageChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="altText" className="form-label">
                        Alt Text:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="altText"
                        value={altText}
                        onChange={(e) => setAltText(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            กำลังอัปโหลด...
                        </>
                    ) : (
                        "อัปโหลด"
                    )}
                </button>
            </form>

            {/* {loading && (
                <div className="text-center my-3">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">กำลังอัปโหลด...</span>
                    </div>
                    <p>กำลังอัปโหลด...</p>
                </div>
            )} */}

            {/* ส่วนแสดงตัวอย่างรูปภาพ */}
            <div className="mt-5">
                <h3>Uploaded Images</h3>
                {uploadedImages.length > 0 ? (
                    <div className="row">
                        {sortedImages.map((image) => (
                            <div className="col-md-4 mb-3" key={image._id}>
                                <div className="card">
                                    <img
                                        src={image.url}
                                        alt={image.alt}
                                        className="card-img-top"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleSelectImage(image)}
                                    />
                                    <div className="card-body text-center">
                                        <button
                                            className={`btn ${image.isSelected ? "btn-success" : "btn-outline-primary"}`}
                                            onClick={() => handleSelectImage(image)}
                                        >
                                            {image.isSelected ? "ยกเลิกการเลือก" : "เลือก"}
                                        </button>
                                        <IconButton
                                            color="error"
                                            className="position-absolute top-0 end-0 m-2"
                                            onClick={() => handleDeleteImage(image._id)}
                                            title="ลบรูปภาพ"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>ยังไม่มีรูปภาพที่อัปโหลด</p>
                )}
            </div>
            <div className="text-center mt-4">
                <button
                    className="btn btn-success"
                    onClick={handleSaveSelectedImages}
                    disabled={uploadedImages.length === 0} // ปิดปุ่มหากไม่มีรูปภาพที่เลือก
                >
                    บันทึกรายการที่เลือก
                </button>
            </div>
            <div className="mt-5">
                <h3>Preview (Demo)</h3>
                {selectedImages.length > 0 ? (
                    <Carousel interval={2000}>
                        {selectedImages.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={image.url}
                                    alt={image.alt}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                ) : (
                    <p>ยังไม่มีรูปภาพที่เลือก</p>
                )}
            </div>
        </div>
    );
};

export default AdminPanel;