import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Avatar, Badge, Spin } from "antd";
import Tesseract from "tesseract.js"; // เพิ่ม Tesseract.js

const FileUpload = ({ images, setImages, setExtractedText }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [loading, setLoading] = useState(false);

  const preprocessImage = (imageSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        const scaleFactor = 2; // ขยายขนาด 2 เท่า
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        // แปลงเป็น Grayscale เท่านั้น (ไม่ทำ Threshold)
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
  
        for (let i = 0; i < data.length; i += 4) {
          let avg = (data[i] + data[i + 1] + data[i + 2]) / 3; // ค่าเฉลี่ย RGB
          data[i] = data[i + 1] = data[i + 2] = avg; // ทำให้เป็นสีเทา (Grayscale)
        }
  
        ctx.putImageData(imageData, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
    });
  };
  const extractReferenceNumber = (text) => {
    const numbers = text.match(
      /\b(?=(.*\d.*\d))(?=.*[A-Za-z])[A-Za-z0-9]{10,}\b|\b\d{10,}\b/g
    );
// ค้นหาทุกกลุ่มที่เป็นตัวเลข
  
    if (!numbers) return "ไม่พบเลขอ้างอิง";
  
    // เลือกตัวเลขที่มีความยาวมากที่สุด
    const longestNumber = numbers.reduce((a, b) => (a.length >= b.length ? a : b));
  
    return longestNumber.length >= 7 ? longestNumber : "ไม่พบเลขอ้างอิง"; // กำหนดขั้นต่ำ 6 หลัก
  };
  

  const handleChangFile = (e) => {
    const files = e.target.files;
    if (files) {
      setLoading(true);
      let allfileUpload = [...images];
  
      for (let i = 0; i < files.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          1200,
          1200,
          "JPEG",
          100,
          0,
          async (uri) => {
            try {
              const res = await axios.post(
                process.env.REACT_APP_API + "/bill",
                { image: uri },
                { headers: { authtoken: user.token } }
              );
              allfileUpload.push(res.data);
              setImages(allfileUpload);
              toast.success("Image uploaded successfully");
  
              // **เพิ่มขั้นตอนปรับคุณภาพภาพ OCR**
              const processedImage = await preprocessImage(uri);
  
              const { data: { text } } = await Tesseract.recognize(processedImage, "eng", {
                logger: (m) => console.log(m),
                tessedit_char_whitelist: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
              });
  
              console.log("Extracted Text:", text);
  
              // ค้นหาเลขอ้างอิงจากข้อความ OCR
              const referenceNumber = extractReferenceNumber(text);
              console.log("Reference Number:", referenceNumber);
  
              setExtractedText(referenceNumber);
            } catch (error) {
              console.error("OCR Error:", error);
              toast.error("Failed to extract text");
            } finally {
              setLoading(false);
            }
          },
          "base64"
        );
      }
    } else {
      toast.error("No files selected");
    }
  };
  const handleRemove = (public_id) => {
    axios
      .post(
        process.env.REACT_APP_API + "/removebill",
        { public_id },
        { headers: { authtoken: user.token } }
      )
      .then((res) => {
        const filteredImages = images.filter((item) => item.public_id !== public_id);
        setImages(filteredImages);
        toast.success("Image removed successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to remove image");
      });
  };

  return (
    <>
      <br />
      {images.map((item) => (
        <span className="avatar-item" key={item.public_id}>
          <Badge
            onClick={() => handleRemove(item.public_id)}
            style={{ cursor: "pointer" }}
            count="x"
          >
            <Avatar className="m-3" src={item.url} shape="square" size={120} />
          </Badge>
        </span>
      ))}
      <hr />
      {loading ? (
        <div className="text-center">
          <Spin tip="Uploading..." />
        </div>
      ) : (
        <div className="form-group">
          <label
            className="btn btn-primary"
            style={{
              backgroundColor: "rgb(233, 57, 139)",
              borderColor: "rgb(233, 57, 139)",
            }}
          >
            Choose File
            <input
              onChange={handleChangFile}
              className="form-control"
              type="file"
              hidden
              multiple
              accept="image/*"
              name="file"
            ></input>
          </label>
        </div>
      )}
      <br />
    </>
  );
};

export default FileUpload;
