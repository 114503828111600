import axios from "axios";

export const createProduct = async (authtoken,value) => {
    return await axios.post(process.env.REACT_APP_API + "/product",value,{
      headers:{
        authtoken,
      }
    } );
  };

export const listProduct = async (count) => {
    return await axios.get(process.env.REACT_APP_API + "/product/"+count);
  };
export const listProductCategory = async (count = 50) => {
    return await axios.get(process.env.REACT_APP_API + "/products-by-category/"+count);
  };
  
  export const listEmployeeProducts = async (count,authtoken) => {
    return await axios.get(process.env.REACT_APP_API + "/products/employee", {
      headers: {
        authtoken,
      },
    });
  };
  

export const removeProduct = async (authtoken,id) => 
     await axios.delete(process.env.REACT_APP_API + "/product/"+id,
      {
        headers:{
          authtoken
        }
      }
    );

// update
export const readProduct = async (id) => {
  return await axios.get(process.env.REACT_APP_API + "/products/"+id);
};

export const updateProduct = async (authtoken,id, product) => 
  await axios.put(process.env.REACT_APP_API + "/product/"+id,product,
   {
     headers:{
       authtoken
     }
   }
 );


 export const listProductBy = async (sort, order,limit) => {
  return await axios.post(process.env.REACT_APP_API + "/productby",{
    sort, 
    order,
    limit,
  });
};

export const searchFilters = async(arg)=>{
  return await axios.post(process.env.REACT_APP_API + '/search/filters', arg)
}

export const getAllProductsByCategoryService = () => {
  return axios.get(`/api/products/categoryService`); // เรียก API ที่จะดึงสินค้าทั้งหมดจาก CategoryService
}

export const listProductByCategoryService = async (sort, order, limit, categorySelect) => {
  return await axios.post(process.env.REACT_APP_API + "/productbycategoryservice", {
    sort,
    order,
    limit,
    categorySelect,  // กรองตาม categoryService ที่เลือก
  });
};