import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileUploadProfile from "./FileUploadProfile";
import { updateProfile } from "../../../function/users";
import axios from "axios";
import "./ManageProfile.css";

const ManageProfile = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [profileImage, setProfileImage] = useState(user?.profileImage?.url || "");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [provinceId, setProvinceId] = useState(null);
  const [amphure, setAmphure] = useState("");
  const [amphureId, setAmphureId] = useState(null);
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [amphures, setAmphures] = useState([]);
  const [districts, setDistricts] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    // สมมติว่าคุณดึงข้อมูลจาก API หรือ Redux store
    // และทำการตั้งค่าผลลัพธ์จากการดึงข้อมูลไปที่ profileImage
    if (user?.profileImage) {
      setProfileImage(user.profileImage); // ตั้งค่ารูปภาพที่ดึงมาจากฐานข้อมูล
    }
  }, [user]);
  // โหลดข้อมูลจังหวัด
  useEffect(() => {
    axios.get("https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json")
      .then((res) => setProvinces(res.data))
      .catch((err) => console.error("Error loading provinces:", err));
  }, []);

  // โหลดข้อมูลอำเภอเมื่อเลือกจังหวัด
  useEffect(() => {
    if (provinceId) {
      axios.get("https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json")
        .then((res) => {
          const filtered = res.data.filter((a) => a.province_id === provinceId);
          setAmphures(filtered);
          setAmphure("");
          setAmphureId(null);
          setDistrict("");
          setZipcode("");
        })
        .catch((err) => console.error("Error loading amphures:", err));
    }
  }, [provinceId]);

  // โหลดข้อมูลตำบลเมื่อเลือกอำเภอ
  useEffect(() => {
    if (amphureId) {
      axios.get("https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json")
        .then((res) => {
          const filtered = res.data.filter((d) => d.amphure_id === amphureId);
          setDistricts(filtered);
          setDistrict("");
          setZipcode("");
        })
        .catch((err) => console.error("Error loading districts:", err));
    }
  }, [amphureId]);

  // ฟังก์ชันบันทึกข้อมูล
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = {
        profileImage,
        address: {
          name: user.username,
          phone: user.phone || "",
          address: `${address}, ${district}, ${amphure}, ${province}, ${zipcode}`,
          email: user.email,
        },
      };
      await updateProfile(user.token, user._id, formData);
      toast.success("อัปเดตข้อมูลสำเร็จ!");
      navigate(`/profile`);
    } catch (err) {
      console.error(err);
      toast.error("เกิดข้อผิดพลาด");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto max-w-2xl p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 mt-5 text-center">จัดการโปรไฟล์</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <FileUploadProfile
          setProfileImage={setProfileImage}
          setLoading={setLoading}
          profileImage={profileImage} // ส่งค่า profileImage ไปด้วย
        />

        <div>
          <label className="block text-gray-700 mt-3">ที่อยู่</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg focus:ring focus:ring-blue-200"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="กรอกที่อยู่ของคุณ"
          />
        </div>

        <div>
          <label className="block text-gray-700">จังหวัด</label>
          <select
            className="w-full p-2 border rounded-lg focus:ring focus:ring-blue-200"
            onChange={(e) => {
              const selected = e.target.value ? JSON.parse(e.target.value) : {};
              setProvince(selected.name_th || "");
              setProvinceId(selected.id || null);
            }}
          >
            <option>-- เลือกจังหวัด --</option>
            {provinces.map((p) => (
              <option key={p.id} value={JSON.stringify(p)}>{p.name_th}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700">อำเภอ</label>
          <select
            className="w-full p-2 border rounded-lg focus:ring focus:ring-blue-200"
            onChange={(e) => {
              const selected = e.target.value ? JSON.parse(e.target.value) : {};
              setAmphure(selected.name_th || "");
              setAmphureId(selected.id || null);
            }}
            disabled={!provinceId}
          >
            <option>-- เลือกอำเภอ --</option>
            {amphures.map((a) => (
              <option key={a.id} value={JSON.stringify(a)}>{a.name_th}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700">ตำบล</label>
          <select
            className="w-full p-2 border rounded-lg focus:ring focus:ring-blue-200"
            onChange={(e) => {
              const selected = e.target.value ? JSON.parse(e.target.value) : {};
              setDistrict(selected.name_th || "");
              setZipcode(selected.zip_code || "");
            }}
            disabled={!amphureId}
          >
            <option>-- เลือกตำบล --</option>
            {districts.map((d) => (
              <option key={d.id} value={JSON.stringify(d)}>{d.name_th}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700">รหัสไปรษณีย์</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg bg-gray-100"
            value={zipcode}
            readOnly
          />
        </div>

        <button
          type="submit"
          className="custom-button mt-3"
          disabled={loading}
        >
          {loading ? "กำลังบันทึก..." : "บันทึกข้อมูล"}
        </button>
      </form>
    </div>
  );
};

export default ManageProfile;
