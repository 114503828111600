import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { listProductCategory } from "../../function/product";
import { useNavigate } from "react-router-dom";

const BranchOrderForm = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState("");
  const [items, setItems] = useState([{ product: "", quantity: 1, price: "" }]);
  const [cartTotal, setCartTotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [username, setUsername] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [products, setProducts] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    loadBranches();
    loadProducts();
    console.log("user.branch:", user.branch);
    console.log("user data:", user?.data);
    console.log("user branch:", user?.data?.branch);
  }, []);



  const loadBranches = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_API + "/branch", {
        headers: { authtoken: user.token },
      });
      setBranches(data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const loadProducts = async () => {
    try {
      const { data } = await listProductCategory(50);
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
    const total = updatedItems.reduce(
      (sum, item) => sum + Number(item.quantity) * Number(item.price || 0),
      0
    );
    setCartTotal(total);
  };

  const addItem = () => {
    setItems([...items, { product: "", quantity: 1, price: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
  
    try {
      const orderData = {
        branch,
        items: items.map((item) => ({
          product: item.product !== "other" ? item.product : undefined, // ใช้ product ถ้าไม่ใช่ "other"
          customProduct: item.product === "other" ? item.customProduct : undefined, // ใช้ customProduct ถ้าเป็น "other"
          quantity: item.quantity,
          price: item.price,
        })),
        cartTotal,
        paymentMethod,
        createdBy: user._id,
        username,
        phoneNumber,
        note,
        images,
      };
  
      await axios.post(process.env.REACT_APP_API + "/branch-order", orderData, {
        headers: { authtoken: user.token },
      });
  
      setMessage("บันทึกคำสั่งซื้อสำเร็จ!");
      setItems([{ product: "", quantity: 1, price: "" }]);
      setCartTotal(0);
  
      setTimeout(() => {
        navigate("/admin/branch-list");
      }, 1000);
    } catch (error) {
      console.error("Error creating branch order:", error);
      setMessage("เกิดข้อผิดพลาดในการบันทึกคำสั่งซื้อ");
    }
    setLoading(false);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">บันทึกคำสั่งซื้อหน้าสาขา</h2>
      {message && <p className="alert alert-info">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="row mt-5">
          <label className="col-sm-2 col-form-label">สาขา:</label>
          <div className="col-sm-8">
            <select
              className="form-control"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              required
            >
              <option value="">-- เลือกสาขา --</option>
              {user?.role === "admin"
                ? branches.map((b) => (
                  <option key={b._id} value={b._id}>
                    {b.name}
                  </option>
                ))
                : branches
                  .filter((b) => b._id === user?.branch)
                  .map((b) => (
                    <option key={b._id} value={b._id}>
                      {b.name}
                    </option>
                  ))}
            </select>

          </div>
        </div>

        <div className="row mt-5">
          <label className="col-md-2 col-form-label">รายการสินค้า:</label>
          <div className="col-sm-8">
            {items.map((item, index) => (
              <div key={index} className="row mt-2">
              <div className="col-md-4">
                <select
                  className="form-control"
                  value={item.product}
                  onChange={(e) => {
                    handleItemChange(index, "product", e.target.value);
                    if (e.target.value === "other") {
                      handleItemChange(index, "customProduct", ""); // Reset custom product field
                    }
                  }}
                  required
                >
                  <option value="">-- เลือกสินค้า --</option>
                  {products.map((p) => (
                    <option key={p._id} value={p._id}>
                      {p.title}
                    </option>
                  ))}
                  <option value="other">อื่นๆ</option> {/* เพิ่มตัวเลือก "อื่นๆ" */}
                </select>
              </div>
              {item.product === "other" && ( // แสดงช่องพิมพ์ชื่อสินค้าเมื่อเลือก "อื่นๆ"
                <div className="col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="กรอกชื่อสินค้า"
                    value={item.customProduct || ""}
                    onChange={(e) => handleItemChange(index, "customProduct", e.target.value)}
                    required
                  />
                </div>
              )}
                <div className="col-md-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="จำนวน"
                    value={item.quantity}
                    min="1"
                    onChange={(e) =>
                      handleItemChange(index, "quantity", e.target.value)
                    }
                    required
                  />
                </div>
                <div className="col-md-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="ราคา"
                    value={item.price}
                    min="0"
                    onChange={(e) =>
                      handleItemChange(index, "price", e.target.value)
                    }
                    required
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              style={{ backgroundColor: "#4CB4F6", color: "#fff" }}
              onClick={addItem}
            >
              เพิ่มรายการสินค้า
            </button>
          </div>
        </div>

        <div className="mb-3">
          <label>ยอดรวม:</label>
          <input
            type="number"
            className="form-control"
            value={cartTotal}
            readOnly
          />
        </div>

        <div className="mb-3">
          <label>วิธีการชำระเงิน:</label>
          <select
            className="form-control"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
          >
            <option value="cash">เงินสด</option>
            <option value="card">บัตรเครดิต/เดบิต</option>
            <option value="bankTransfer">โอนเงินผ่านธนาคาร</option>
          </select>
        </div>

        <div className="mb-3">
          <label>ชื่อผู้สั่งซื้อ:</label>
          <input
            type="text"
            className="form-control"
            placeholder="ชื่อผู้สั่งซื้อ"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>เบอร์โทรศัพท์:</label>
          <input
            type="tel"
            className="form-control"
            placeholder="กรอกเบอร์โทรศัพท์"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label>หมายเหตุ:</label>
          <textarea
            className="form-control"
            placeholder="กรอกหมายเหตุเพิ่มเติม (ถ้ามี)"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>

        <button
          type="submit"
          style={{ backgroundColor: "#43bc43", color: "#fff" }}
          disabled={loading}
        >
          {loading ? "กำลังบันทึก..." : "บันทึกคำสั่งซื้อ"}
        </button>
      </form>
    </div>
  );
};

export default BranchOrderForm;
