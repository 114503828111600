import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VisitList = () => {
  const [visits, setVisits] = useState([]);  // ตั้งค่าเริ่มต้นเป็นอาร์เรย์เปล่า
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit] = useState(10);  // จำนวนรายการที่จะแสดงในแต่ละหน้า

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await axios.get(`http://localhost:4000/api/visits?page=${currentPage}&limit=${limit}`);
        setVisits(response.data.visits || []);  // ตรวจสอบว่า data.visits มีหรือไม่ และตั้งค่าเป็นอาร์เรย์
        setTotalPages(response.data.totalPages || 0);  // ตรวจสอบ totalPages หากไม่มีให้ตั้งเป็น 0
        setLoading(false);
      } catch (err) {
        setError("Error fetching visit data");
        setLoading(false);
      }
    };

    fetchVisits();
  }, [currentPage, limit]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='container mt-5'>
      <h2>Visit Log</h2>
      <table>
        <thead>
          <tr>
            <th>Path</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {visits && visits.length > 0 ? (
            visits.map((visit) => (
              <tr key={visit._id}>
                <td>{visit.path}</td>
                <td>{new Date(visit.timestamp).toLocaleString()}</td> {/* แปลง timestamp ให้เป็นวันที่ */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No visits found</td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Pagination */}
      <div>
        {Array.from({ length: totalPages }, (_, index) => (
          <button key={index + 1} onClick={() => handlePageChange(index + 1)} disabled={currentPage === index + 1}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default VisitList;
