import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Avatar } from "antd";

const FileUploadProfile = ({ setProfileImage, setLoading, profileImage }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [previewImage, setPreviewImage] = useState(profileImage || null);

  // ใช้ useEffect เพื่อให้แน่ใจว่า previewImage จะอัปเดตทุกครั้งที่ profileImage เปลี่ยนแปลง
  useEffect(() => {
    if (profileImage) {
      setPreviewImage(profileImage);  // อัปเดต previewImage จาก profileImage ที่ได้รับ
    }
  }, [profileImage]);

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true); // ตั้งสถานะกำลังโหลด

      // แสดงตัวอย่างรูปที่เลือก
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result); // แสดงตัวอย่างรูป
      };
      reader.readAsDataURL(file);

      // ทำการปรับขนาดรูปก่อนอัปโหลด
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          try {
            const res = await axios.post(
              `${process.env.REACT_APP_API}/images-profile`,
              { image: uri },
              {
                headers: {
                  authtoken: user?.token, // ใช้ token สำหรับการตรวจสอบสิทธิ์
                },
              }
            );
            setLoading(false); // ยกเลิกสถานะกำลังโหลด
            setProfileImage(res.data); // บันทึกข้อมูลรูปภาพที่อัปโหลด
            toast.success("Image uploaded successfully");
          } catch (err) {
            setLoading(false); // ยกเลิกสถานะกำลังโหลด
            console.error(err);
            toast.error("Image upload failed");
          }
        },
        "base64"
      );
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-50">
      {/* แสดงตัวอย่างรูปที่เลือก */}
      {previewImage ? (
        <div
          style={{
            width: "150px",
            height: "150px",
            borderRadius: "50%",
            overflow: "hidden",
            marginBottom: "20px",
          }}
        >
          <img
            src={previewImage?.url || previewImage?.preview || ""}
            alt="Profile Preview"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      ) : (
        <div
          style={{
            width: "150px",
            height: "150px",
            borderRadius: "50%",
            backgroundColor: "#e0e0e0",
            marginBottom: "20px",
          }}
        >
          <Avatar icon={<i className="fas fa-user"></i>} size={150} />
        </div>
      )}

      {/* ปุ่มเลือกไฟล์ */}
      <input
        type="file"
        accept="image/*"
        onChange={handleChangeFile}
        value={previewImage ? "" : undefined} // ถ้ามีรูปแล้วให้ล้างค่า value เพื่อให้ไม่แสดง 'No file chosen'
      />
    </div>
  );
};

export default FileUploadProfile;
