import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { currentStaff } from "../function/auth";

const StaffRoute = ({ children }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      currentStaff(user.token)
        .then((res) => {
          console.log("STAFF AUTH SUCCESS:", res);
          setOk(true);
        })
        .catch((err) => {
          console.log("STAFF AUTH ERROR:", err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? children : <LoadingToRedirect />;
};

export default StaffRoute;
