import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineShop, AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux"; // ใช้ Redux เพื่อตรวจสอบการล็อกอิน
import { toast } from "react-toastify"; // เพิ่ม toast สำหรับแจ้งเตือน
import "react-toastify/dist/ReactToastify.css"; // นำเข้า CSS ของ toastify
import "./BottomNav.css";

function BottomNav() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({ ...state })); // ดึงข้อมูลผู้ใช้จาก Redux

  const handleProfileClick = () => {
    if (user && user.token) {
      navigate("/profile");
    } else {
      toast.warning("กรุณาเข้าสู่ระบบก่อนใช้งาน!", { position: "top-center" }); // แจ้งเตือน
      navigate("/login");
    }
  };
  const [profileImage, setProfileImage] = useState(null);

useEffect(() => {
  if (user?.profileImage?.url) {
    setProfileImage(user.profileImage.url);  // อัปเดต profileImage ให้ตรงกับข้อมูลใน Redux
  } else {
    setProfileImage(null);  // ถ้าไม่มีรูปโปรไฟล์
  }
}, [user]);  // เมื่อ user เปลี่ยนแปลงจะทำงานใหม่


  return (
    <div className="bottom-nav" style={styles.navContainer}>
      <button style={styles.navButton} onClick={() => navigate("/")}>
        <AiOutlineHome size={24} />
        <span>Home</span>
      </button>
      <button style={styles.navButton} onClick={() => navigate("/shop")}>
        <AiOutlineShop size={24} />
        <span>Shop</span>
      </button>
      <button style={styles.navButton} onClick={() => navigate("/cart")}>
        <AiOutlineShoppingCart size={24} />
        <span>Cart</span>
      </button>

      {/* ปรับการแสดงผล Profile */}
      <button style={styles.navButton} onClick={handleProfileClick}>
        {/* ถ้ามีรูปโปรไฟล์ ให้แสดงรูป ถ้าไม่มีให้แสดงไอคอน */}
        {user && user.profileImage && user.profileImage.url ? (
  <img src={user.profileImage.url} alt="Profile" style={styles.profileImage} />
) : (
  <AiOutlineUser size={24} />
)}
        <span>Profile</span>
      </button>
    </div>
  );
}

const styles = {
  navContainer: {
    display: "flex",
    justifyContent: "space-around",
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff",
    padding: "10px 0",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  },
  navButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  },
  profileImage: {
    width: "24px", // ขนาดของรูป
    height: "24px",
    borderRadius: "50%", // ทำให้รูปเป็นวงกลม
    objectFit: "cover", // ทำให้รูปพอดีกับขนาด
  },
};

export default BottomNav;
