import axios from "axios";

export const getBranchOrders = async (authtoken) => {
    return await axios.get(process.env.REACT_APP_API + "/branch-orders", {
        headers: {
            authtoken,
        },
    });
};

export const getBranchOrdersByBranch = async (authtoken, branchId) => {
    return await axios.get(`${process.env.REACT_APP_API}/branch-orders/${branchId}`, {
      headers: { authtoken },
    });
  };

  export const getBranches = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/branch`, {
      headers: { authtoken },
    });
  }; 
