import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"; // ใช้ navigate
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { UserOutlined, EditOutlined } from "@ant-design/icons"; // ใช้ไอคอน Edit
import "../ProfileUsers/Profile.css"
import { UserAddOutlined, AppstoreAddOutlined, ShoppingOutlined, ScheduleOutlined, GiftOutlined, LogoutOutlined } from "@ant-design/icons"; // สำหรับใช้ไอคอน

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state })); // ดึงข้อมูล user จาก Redux
  const [profile, setProfile] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/users/${user._id}`);
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchUserProfile();
  }, [user._id]);
  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
    setExpanded(false);
  };

  return (
    <div className="container mt-2">
      <div style={styles.container}>
        <div className="row">
          {profile ? (
            <>
              <div className="col-6 col-md-3 mt-1">
                <p style={styles.profileHeading}>โปรไฟล์ของ {profile.username}</p>

                {/* วางรูปโปรไฟล์และปุ่มแก้ไข */}
                <div style={styles.profileImageContainer}>
                  {profile?.profileImage?.url ? (
                    <img
                      src={profile.profileImage.url}
                      alt="Profile"
                      style={styles.profileImage}
                    />
                  ) : (
                    <div style={styles.profileIcon}>
                      <UserOutlined style={{ fontSize: "80px", color: "#aaa" }} />
                    </div>
                  )}
                  {/* ปุ่มแก้ไข */}
                  <button
                    onClick={() => navigate("/edit-profile")}
                    style={styles.editIcon}
                  >
                    <EditOutlined style={{ fontSize: "20px", color: "#fff" }} />
                  </button>
                </div>

                {/* ข้อมูลโปรไฟล์ */}
                <p>Email: {profile.email}</p>
                <p>ที่อยู่: {profile.address?.address}</p>
                {/* <p>แต้มสะสม: {profile.points}</p> */}

                {/* ปุ่มแก้ไขโปรไฟล์ */}
                <button onClick={() => navigate("/edit-profile")} style={styles.button}>
                  แก้ไขโปรไฟล์
                </button>
              </div>
              <div className="col-6 col-md-3">
                <div style={styles.menuList}>
                  <Link to={`/profile`} style={styles.menuItem}>
                    <UserAddOutlined /> โปรไฟล์
                  </Link>
                  <Link to={user.role === "admin" ? "/admin/index" : "/user/index"} style={styles.menuItem}>
                    <AppstoreAddOutlined /> Dashboard
                  </Link>
                  <Link to="/shop" style={styles.menuItem}>
                    <ShoppingOutlined /> ร้านค้า
                  </Link>
                  <Link to="/user/order-tracking" style={styles.menuItem}>
                    <ScheduleOutlined /> ติดตามคำสั่งซื้อ
                  </Link>
                  <Link to="/promotion-user" style={styles.menuItem}>
                    <GiftOutlined /> โปรโมชั่น
                  </Link>
                  <Link onClick={logout} style={styles.menuItem}>
                    <LogoutOutlined /> Logout
                  </Link>
                </div>
              </div>

              <div className="col-md-6 tiltlecard">
                <h2>MemberCard</h2>
                <div className="col-md-12 card-Allmember">
                <p style={styles.idText}>ID: {profile._id.replace(/\D/g, '').substring(0, 4)}</p>
                  
                </div>
                <p></p>
                <p>แต้มสะสม: {profile.points}</p>

              </div>
            </>

          ) : (
            <p>กำลังโหลด...</p>
          )}

        </div>

      </div>
    </div>

  );
};

const styles = {
  container: {
    padding: "20px",
  },
  profileImageContainer: {
    position: "relative",
    display: "inline-block",
  },
  profileImage: {
    width: "120px",
    height: "120px",
    borderRadius: "50%", // ทำให้รูปเป็นวงกลม
    border: "4px solid #fff",
    objectFit: "cover",
  },
  profileIcon: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    border: "4px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f0f0f0", // สีพื้นหลังให้ไอคอนดูเด่นขึ้น
  },
  editIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: "#FF70A6",
    border: "none",
    borderRadius: "50%",
    padding: "10px",
    cursor: "pointer",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  button: {
    marginTop: "10px",
    padding: "5px 10px",
    fontSize: "12px",
    backgroundColor: "#FF70A6",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  profileHeading: {
    fontSize: "16px", // เปลี่ยนขนาดตามที่ต้องการ
    fontWeight: "bold", // สามารถเพิ่มน้ำหนักของตัวอักษรได้
  },

  menuList: {
    display: "flex",
    flexDirection: "column", // ทำให้รายการเรียงจากบนลงล่าง
    gap: "10px", // ระยะห่างระหว่างรายการ
  },
  menuItem: {
    color: "#333", // ปรับสีข้อความ
    textDecoration: "none", // เอาเส้นใต้ลิงก์ออก
    padding: "10px", // เพิ่ม padding เพื่อให้มีระยะห่าง
    borderRadius: "5px", // ทำให้มุมของลิงก์โค้ง
    transition: "background-color 0.3s ease", // เพิ่มเอฟเฟกต์ตอน hover
  },
  menuItemHover: {
    backgroundColor: "#f0f0f0", // สีพื้นหลังเมื่อ hover
  },
  idText: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    margin: 0,
  },
};

export default Profile;
