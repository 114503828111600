import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductCardEmployee from "../../card/ProductCardEmployee";
import ProductCard from "../../card/ProductCard";
import { listEmployeeProducts, listProduct } from "../../function/product";
import { Select, Slider } from "antd";
import Footer from "../../layouts/Footer";
import "./ShopEmployee.css"

const { Option } = Select;

const ShopEmployee = () => {
  const [employeeProducts, setEmployeeProducts] = useState([]);
  const [generalProducts, setGeneralProducts] = useState([]);
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const { user } = useSelector((state) => state);

  useEffect(() => {
    if (user && user.role === "employee") {
      loadEmployeeProducts();
    }
    loadGeneralProducts();
  }, [user]);

  const loadEmployeeProducts = async () => {
    setLoadingEmployee(true);
    try {
      const res = await listEmployeeProducts(20, user.token);
      setEmployeeProducts(res.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingEmployee(false);
  };

  const loadGeneralProducts = async () => {
    setLoadingGeneral(true);
    try {
      const res = await listProduct(20); // API สินค้าทั่วไป
      setGeneralProducts(res.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingGeneral(false);
  };


  return (
    <div className="container ">
      <div className="row mt-4">
        <div className="row mt-4">
          <h4>สินค้าสำหรับพนักงาน</h4>
        </div>

        {loadingEmployee ? <p>กำลังโหลด...</p> : (
          <div className="row">
            {employeeProducts.map((product) => (
              <div className="col-6 col-md-3 mb-3" key={product._id}>
                <ProductCardEmployee product={product} />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="row mt-4">
        <h4>สินค้าทั่วไป</h4>
        {loadingGeneral ? <p>กำลังโหลด...</p> : (
          <div className="row">
            {generalProducts.map((product) => (
              <div className="col-6 col-md-3 mb-3" key={product._id}>
                <ProductCard product={product} />
              </div>
            ))}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default ShopEmployee;
