import React, { useEffect, useState } from 'react';
import { Card, Rate } from 'antd';
import { EyeOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { listPromotions } from "../function/promotion";
import { useNavigate } from 'react-router-dom';
import './ProductCardHome.css';

const ProductCardHome = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [hasPromotion, setHasPromotion] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const { _id, title, description, images, price, averageRating } = product;

  const isPromotion = product.discountedPrice !== undefined && product.discountedPrice !== null;

  // ✅ กำหนดราคาที่จะแสดง (ใช้ `discountedPrice` ถ้ามี)
  const displayedPrice = isPromotion ? product.discountedPrice : product.price;
  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const res = await listPromotions();
        const promotions = res.data;
        const productPromotion = promotions.find(promotion =>
          promotion.products.some(p => p._id === product._id)
        );

        if (productPromotion) {
          setHasPromotion(true);
          const discount = productPromotion.discount;
          const discounted = price - (price * discount / 100);
          setDiscountedPrice(discounted);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPromotions();
  }, [product, price]);

  const handleAddToCart = (event) => {
    event.stopPropagation();
    let cart = [];
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }
    cart.push({
      ...product,
      count: 1,
      discountedPrice: discountedPrice !== null ? discountedPrice : price,
    });

    let unique = _.uniqWith(cart, _.isEqual);
    localStorage.setItem('cart', JSON.stringify(unique));
    dispatch({
      type: 'ADD_TO_CART',
      payload: unique
    });
    dispatch({
      type: 'SET_VISIBLE',
      payload: true
    });
  };

  // const shortenedDescription = description.slice(0, 75);

  const handleCardClick = () => {
    navigate(`/product/${_id}`);
  };

  const formattedPrice = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(price);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const priceStyle = {
    fontSize: windowWidth <= 576 ? '0.8rem' : '1.2rem',
    fontFamily: 'Sarabun, serif',
  };

  const titleStyle = {
    fontSize: windowWidth <= 576 ? '0.8rem' : '1.2rem',
    fontFamily: 'Sarabun, serif',
  };

  const cardWidth = windowWidth <= 576 ? '120px' : '240px';

  return (
    <div className='product-card-container'>
      {hasPromotion && <div className='promotion-label'>Promotion</div>}
      <div
        className={`product-card ${isHovered ? 'hovered' : ''}`}
        onClick={handleCardClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ width: cardWidth }}
      >
        <img
          className='product-card-image'
          src={images && images.length ? images[0].url : ''}
          alt={title}
          style={{ height: windowWidth <= 576 ? '90px' : '240px', width: cardWidth }}
        />
        <div className='product-card-body'>
          <h5 style={titleStyle}>{title}</h5>
          {/* <p>{windowWidth > 576 ? shortenedDescription : null}</p> */}
          <div className="product-price">
            {discountedPrice ? (
              <>
                <h4 style={priceStyle}>
                  {/* แสดงราคาปกติแบบขีดฆ่า */}
                  <span style={{ textDecoration: 'line-through', marginRight: '8px', color: '#999' }}>
                    {new Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(product.price)}
                  </span>

                  {/* แสดงราคาส่วนลดเป็นสีแดง */}
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    {new Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(discountedPrice)}
                  </span>
                </h4>
              </>
            ) : (
              // แสดงราคาปกติถ้าไม่มีส่วนลด
              <h4 style={priceStyle}>
                {new Intl.NumberFormat('th-TH', {
                  style: 'currency',
                  currency: 'THB',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }).format(product.price)}
              </h4>
            )}
          </div>
          <div className="rating-container">
            {product.averageRating > 0 && (
              <Rate disabled value={product.averageRating} style={{ fontSize: 8 }} />
            )}
            {/* <span className="rating-value">{averageRating?.toFixed(1) || "0.0"}</span> */}
          </div>
          {/* <button className="btn btn-danger" onClick={handleAddToCart}>
            <ShoppingCartOutlined /> Add to Cart
          </button> */}
        </div>
        <div className="product-card-footer">
          {/* <button className="btn btn-link" onClick={handleCardClick}>
            <EyeOutlined className="text-warning" />
            View Details
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ProductCardHome;
