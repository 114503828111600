import React from "react";
import { pdf } from "@react-pdf/renderer";
import OrderPDF from "./OrderPDF";

const OrderPopup = ({ order, onClose }) => {
  // ฟังก์ชันสร้างและดาวน์โหลด PDF
  const handleDownloadPDF = async () => {
    console.log("Order Data:", order); // ✅ ตรวจสอบข้อมูลที่ส่งไปยัง PDF

    const blob = await pdf(<OrderPDF order={order} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Order_${order.orderNumber}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h3 className="text-xl font-bold mb-4">รายละเอียดคำสั่งซื้อ</h3>
        <p>
          <strong>หมายเลขออเดอร์:</strong> {order.orderNumber}
        </p>
        <p>
          <strong>สาขา:</strong> {order.branch?.name || "-"}
        </p>
        {/* <p>
          <strong>สถานะ:</strong> {order.orderStatus}
        </p> */}
        <p>
          <strong>ผู้ทำรายการ:</strong> {order.createdBy?.name || "-"}
        </p>
        <p>
          <strong>หมายเหตุ:</strong> {order.note || "-"}
        </p>
        

        <div className="flex justify-between mt-4">
          <button
            onClick={onClose}
            style={{
              backgroundColor: "#d61919", // สีพื้นหลังปุ่ม
              color: "white", // สีตัวอักษร
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            ปิด
          </button>

          <button
            style={{
              backgroundColor: "#43bc43", // สีพื้นหลังปุ่ม
              color: "white", // สีตัวอักษร
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleDownloadPDF}
          >
            ดาวน์โหลด PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;
