import React from 'react';
import { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Badge, Container } from 'react-bootstrap';  // เพิ่ม Container ที่นี่
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './ResponsiveNavbar.css'; // เพิ่มไฟล์ CSS
import logo from "./img/logo192.png";
import Search from "../card/Search";
import {
  HomeOutlined,
  UserAddOutlined,
  LoginOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ScheduleOutlined,
  GiftOutlined,
  AppstoreAddOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";

const ResponsiveNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, cart } = useSelector((state) => ({ ...state }));
  const [profileImage, setProfileImage] = useState("");
  const [expanded, setExpanded] = useState(false); // ⬅️ จัดการสถานะของเมนู

  useEffect(() => {
    if (user?.profileImage?.url) {
      setProfileImage(user.profileImage.url);
    }
  }, [user]);

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" className="navbar-custom" expanded={expanded}>
      <Container>  {/* ใช้ Container เพื่อจัดระเบียบการแสดงผล */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          {/* <Link to="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                marginRight: "10px",
                marginLeft: "40px",
              }}
            />
          </Link> */}
        </Navbar.Brand>

        {/* ปรับปุ่ม Toggle ให้แสดง 3 ขีดและข้อความ MENU */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="custom-navbar-toggle"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        >
          <span className="menu">MENU</span>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" onSelect={() => setExpanded(false)}> {/* ⬅️ ปิดเมนูเมื่อเลือกเมนู */}
            <Nav.Link as={Link} to="/" eventKey="1">Home</Nav.Link>
            <Nav.Link as={Link} to="/shop" eventKey="2">Shop</Nav.Link>
            <Nav.Link href="#groceries" eventKey="3">Hot Deals</Nav.Link>
            <Nav.Link href="#health" eventKey="4">Best Sellers</Nav.Link>
            <Nav.Link href="#vitamins" eventKey="5">New Arrivals</Nav.Link>
            <Nav.Link as={Link} to="/about" eventKey="6">About us</Nav.Link>
            <Nav.Link as={Link} to="/partner" eventKey="7">Partner Center</Nav.Link>
            {user?.role === "employee" && (
              <Nav.Link as={Link} to="/employ-shop" eventKey="8">Employee Shop</Nav.Link>
            )}

          </Nav>

          <Nav className="ms-auto" onSelect={() => setExpanded(false)}> {/* ⬅️ ปิดเมนูเมื่อเลือกเมนู */}
            <Search />
            <Nav.Link as={Link} to="/cart" className="flex-fill text-center" eventKey="9">
              <ShoppingCartOutlined />
              <Badge pill bg="secondary" className="ms-1">{cart.length}</Badge>
              ตะกร้าสินค้า
            </Nav.Link>

            {user ? (
              <NavDropdown
                title={
                  <>
                    {profileImage ? (
                      <img src={profileImage} alt="profile" className="profile-img" />
                    ) : (
                      <UserOutlined style={{ fontSize: "24px", marginRight: "8px" }} />
                    )}
                    {user.username}
                  </>
                }
                id="basic-nav-dropdown"
                className="user-dropdown"
              >
                <NavDropdown.Item as={Link} to={`/profile`} eventKey="10">
                  <UserAddOutlined /> โปรไฟล์
                </NavDropdown.Item>

                {["staff", "admin"].includes(user?.role) && (
                  <Nav.Link as={Link} to="/admin/branch" eventKey="8">
                    ออกใบเสร็จ
                  </Nav.Link>
                )}




                {(user?.role === "staff" || user?.role === "admin") && (
                  <Nav.Link as={Link} to="/admin/branch-list" eventKey="7">รายการสั่งซื้อ</Nav.Link>
                )}

                <NavDropdown.Item as={Link} to={user.role === "admin" ? "/admin/index" : "/user/index"} eventKey="11">
                  <AppstoreAddOutlined /> Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/shop" eventKey="12">
                  <ShoppingOutlined /> ร้านค้า
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/user/order-tracking" eventKey="13">
                  <ScheduleOutlined /> ติดตามคำสั่งซื้อ
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/promotion-user" eventKey="14">
                  <GiftOutlined /> โปรโมชั่น
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logout} eventKey="15">
                  <LogoutOutlined /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/login" className="flex-fill text-center" eventKey="16">
                  <LoginOutlined /> Login
                </Nav.Link>
                <Nav.Link as={Link} to="/register" className="flex-fill text-center" eventKey="17">
                  <UserAddOutlined /> Register
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ResponsiveNavbar;